import React, {
  Fragment,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {getStatusSet} from '../Utils/LogisticUtil';
import {
  DELIVERY_TYPE,
  EZSHIP_STATE,
  LOGISTIC_TRACKING_URL,
} from '../dictionary';
import * as Ant from 'antd';
import DateTimePicker from '../Components/DateTimePicker';
import moment from 'moment';
import {isOrderDisabled, parseCartData} from '../Utils/OrderUtil';
import {useOutlet} from 'reconnect.js';
import AddressField from '../Components/AddressField';
import {Close} from '@styled-icons/material/Close';
import Hint from '../Widgets/Hint';
import Button from '../Widgets/Button';
import Row from '../Widgets/Row';
import Block from '../Widgets/Block';
import Divider from '../Widgets/Divider';
import Anchor from '../Widgets/Anchor';
const appConfig = require('../data.json');

const deliveryConfigData = {
  // ezship data:
  st_state: '', // 通路代號
  st_code: '', // 門市代號
  rstore_name: '', // 門市名稱
  rstore_tel: '', // 門市電話
  rstore_addr: '', // 門市地址
};

function mapping(logistic, cart) {
  let _logistic = {
    logistics_note: '',
    is_delivery_private: false,
    sender_zip: '',
    sender_city: null,
    sender_district: null,
    sender_address: '',
    sender_name: '',
    sender_phone: '',
    sender_tel: '',
    logistics_status: 'default',
    logistics_type: appConfig.supportLogistics[0],
    tracking_number: '',
    receipt_time: null,
    shipping_time: null,
    created: null,
    buyerName: '',
    zip_code: '',
    receiver_city: null,
    receiver_district: null,
    receiver_address: '',
    receiver_name: '',
    receiver_phone: '',
    receiver_tel: '',
  };
  if (logistic) {
    const mergedLogistic = {
      ...logistic,
      buyerName: logistic.buyer ? logistic.buyer.name : '-',
      logistics_note: logistic?.logistics_note || '易碎品請勿重摔',
    };
    return mergedLogistic;
  } else if (cart) {
    let {deliveryConfig} = cart.config;
    return {
      ..._logistic,
      ...deliveryConfig,
      zip_code: deliveryConfig.zip || _logistic.zip_code,
      logistics_type:
        deliveryConfig.delivery_type === 'payuni'
          ? 'hct'
          : deliveryConfig.delivery_type
          ? deliveryConfig.delivery_type
          : _logistic.logistics_type,
      logistics_note: deliveryConfig.delivery_note || '易碎品請勿重摔',
    };
  } else {
    return _logistic;
  }
}

const UISTATE = {
  CREATE: 0,
  EDIT: 1,
};

export default function LogisticDialog(props) {
  const {closeDialog, onUpdate, params, selected} = props;
  const [order, setOrder] = useState(props.order);
  const cart = useMemo(() => parseCartData(order), [order]);
  const [logistic, setLogistic] = useState(mapping(selected, cart));
  const [uiState, setUiState] = useState(
    selected ? UISTATE.EDIT : UISTATE.CREATE,
  );
  const [loading, setLoading] = useOutlet('loading');
  const [actions] = useOutlet('actions');

  let statusSet = useMemo(() => getStatusSet({type: logistic.logistics_type}), [
    logistic.logistics_type,
  ]);

  const {disabled, reason: disabledReason} = useMemo(() => {
    if (isOrderDisabled(order)) {
      return {
        disabled: true,
        reason: '此訂單在不可編輯的訂單狀態',
      };
    }
    if (uiState === UISTATE.CREATE && logistic.logistics_type === 'ezship') {
      return {
        disabled: true,
        reason: '不能由後台產生台灣便利配物流單',
      };
    }
    return {
      disabled: false,
      reason: '',
    };
  }, [order, uiState, logistic]);

  const tracking_data = useMemo(() => {
    try {
      return JSON.parse(logistic.tracking_data);
    } catch (err) {
      return {};
    }
  }, [logistic]);

  const calcCartItemsRequireDays = useCallback((items) => {
    let days = 0;

    items.forEach((item) => {
      if (item.require_day) {
        days = days + item.require_day;
      }
    });

    return days;
  }, []);

  const setRecord = useCallback(
    (obj) => setLogistic((prev) => ({...prev, ...obj})),
    [],
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (selected && !props.order) {
          let resp = await actions.getOrder(selected.order);
          setOrder(resp);
        }
      } catch (err) {
        Ant.message.error('取得訂單資訊錯誤');
      }
      setLoading(false);
    })();
  }, [selected, props.order]);

  useEffect(() => {
    if (params && Object.keys(params).length > 1) {
      setUiState(params.type === 'edit' ? UISTATE.EDIT : UISTATE.CREATE);
      setLogistic(mapping(selected, cart));
      setRecord({
        logistics_type: params?.store_type === 'FAMI' ? 'xdelivery' : 'payuni',
        store_id: params.st_code || '',
        store_name: params.rstore_name || '',
        store_address: params.rstore_addr || '',
      });
    }
  }, [params, selected]);

  const edit = useCallback(async () => {
    setLoading(true);
    let params = {...logistic};

    delete params.order;

    if (params.receiver_phone && !params.receiver_tel) {
      delete params.receiver_tel;
    }

    try {
      await actions.editLogistic(params);
      Ant.message.success('出貨單更新完成');

      onUpdate();
      closeDialog();
    } catch (err) {
      console.warn(err);
      Ant.message.error('更新出貨單錯誤');
    }
    setLoading(false);
  }, [logistic]);

  const create = useCallback(async () => {
    setLoading(true);

    let params = {
      ...logistic,
      order: order.id,
    };

    if (params.receiver_phone && !params.receiver_tel) {
      delete params.receiver_tel;
    }

    delete params.id;

    try {
      await actions.createLogistic(params);
      Ant.message.success('出貨單成立完成');

      onUpdate();
      closeDialog();
    } catch (ex) {
      Ant.message.error('建立出貨單錯誤');
      console.warn(ex);
    }
    setLoading(false);
  }, [logistic]);

  const handleSelectStore = () => {
    const url = new URL(`${appConfig.endpoint.webUrl}/order/`);
    // 這邊跟列表不同，使用的是 id 而不是 display_id，因為 route 的關係
    url.searchParams.append('id', order.id);
    url.searchParams.append(
      'type',
      uiState === UISTATE.CREATE ? 'create' : 'edit',
    );
    if (logistic?.id) {
      url.searchParams.append('logistic_id', logistic?.id);
    }
    window.open(actions.getXDeliveryShipMap(encodeURIComponent(url)), '_blank');
  };

  return (
    <Ant.Modal
      visible={true}
      title={uiState === UISTATE.EDIT ? `編輯物流單` : `新增物流單`}
      footer={
        <Row
          style={{
            justifyContent: 'flex-end',
          }}>
          {disabledReason && <Hint>{disabledReason}</Hint>}
          <Button style={{marginRight: 10}} onClick={() => closeDialog()}>
            取消
          </Button>
          <Button
            type="primary"
            loading={loading}
            disabled={loading || disabled}
            onClick={() => {
              if (uiState === UISTATE.CREATE) {
                create();
              } else {
                edit();
              }
            }}>
            {uiState === UISTATE.CREATE ? '成立出貨單' : '確認'}
          </Button>
        </Row>
      }
      closeIcon={
        <Close style={{position: 'absolute', right: 20, top: 20}} size={20} />
      }
      onCancel={closeDialog}
      width={1200}>
      <Block>
        {!order ? (
          '取得訂單資料...'
        ) : (
          <div style={{padding: 0}}>
            {uiState === UISTATE.EDIT && (
              <Ant.Row gutter={[10, 10]} style={{}}>
                <Ant.Col span={12}>
                  <div className="row">
                    <h4>物流單編號</h4>
                    <div># {logistic.id}</div>
                  </div>
                </Ant.Col>
                <Ant.Col span={12}>
                  <div className="row">
                    <h4>訂單編號</h4>
                    <div>{order.display_id}</div>
                  </div>
                </Ant.Col>
                <Ant.Col span={12}>
                  <div className="row">
                    <h4>建立時間</h4>
                    <div>
                      {moment(logistic.created).format('YYYY-MM-DD HH:mm')}
                    </div>
                  </div>
                </Ant.Col>
                <Ant.Col span={12}>
                  <div className="row">
                    <h4>客戶帳號</h4>
                    <div>{logistic.buyerName}</div>
                  </div>
                </Ant.Col>
              </Ant.Row>
            )}
            <Divider>物流內容</Divider>
            <div className="row">
              <h4>運送方式</h4>
              <Ant.Select
                value={logistic.logistics_type}
                onChange={(value) => setRecord({logistics_type: value})}>
                {Object.keys(DELIVERY_TYPE)
                  .filter((key) => appConfig.supportLogistics.includes(key))
                  .filter((key) => (key === 'ezship' ? appConfig.alpha : true))
                  .map((key) => (
                    <Ant.Select.Option
                      value={key}
                      key={key}
                      disabled={key === 'payuni'}>
                      {DELIVERY_TYPE[key]}
                    </Ant.Select.Option>
                  ))}
              </Ant.Select>
            </div>
            {uiState === UISTATE.EDIT && (
              <React.Fragment>
                <div className="row">
                  <h4>物流狀態</h4>

                  <Ant.Select
                    value={logistic.logistics_status}
                    onChange={(value) => setRecord({logistics_status: value})}>
                    {Object.keys(statusSet).map((key) => (
                      <Ant.Select.Option
                        value={key}
                        key={key}
                        disabled={
                          [
                            'default',
                            'packaging',
                            'tallying',
                            'pending',
                          ].includes(logistic.logistics_status)
                            ? ![
                                'default',
                                'packaging',
                                'tallying',
                                'pending',
                                'transit',
                              ].includes(key)
                            : false
                        }>
                        {statusSet[key]}
                      </Ant.Select.Option>
                    ))}
                  </Ant.Select>
                </div>
                <div className="row" style={{margin: 0}}>
                  <h4></h4>
                  <Hint type="info">除新竹物流，請手動更新物流狀態</Hint>
                </div>
                <div className="row">
                  <h4></h4>
                  <Hint type="info">
                    手動更新，請注意狀態的流程：已出貨(一定要經過) {'>'} 已送達
                  </Hint>
                </div>
              </React.Fragment>
            )}
            {[
              'hct',
              'mailing',
              'tcat',
              'kerry_tj',
              'maple',
              'ezship',
              'sf',
              'payuni',
              'xdelivery',
            ].includes(logistic.logistics_type) && (
              <div className="row">
                <h4>{'追蹤單號'}</h4>
                <Ant.Input
                  type="text"
                  value={logistic.tracking_number}
                  onChange={(e) => setRecord({tracking_number: e.target.value})}
                />
                {LOGISTIC_TRACKING_URL[logistic.logistics_type] ? (
                  <Button
                    href={LOGISTIC_TRACKING_URL[logistic.logistics_type]}
                    target="_blank"
                    rel="noreferrer"
                    type="primary"
                    size="small"
                    color={appConfig.colors.sub}
                    style={{marginLeft: 5}}>
                    查件連結
                  </Button>
                ) : null}
                {logistic.logistics_type === 'hct' && (
                  <Hint type="info">
                    新竹物流-狀態為等待出貨時，物流單填入追蹤碼，狀態會自動改為已出貨
                  </Hint>
                )}
              </div>
            )}
            <div className="row">
              <h4>備註</h4>
              <Ant.Input.TextArea
                value={logistic.logistics_note}
                onChange={(e) => setRecord({logistics_note: e.target.value})}
              />
            </div>
            <div className="row">
              <h4>
                預計
                {logistic.logistics_type === 'self_pick' ? '可自取' : '出貨'}
                時間
              </h4>
              <DateTimePicker
                allowNull={true}
                dateOnly={true}
                datetime={logistic.shipping_time}
                onChange={(value) => setRecord({shipping_time: value})}
                placeholder="計算後填入時間"
              />
              <Hint type="info">
                預計完成天數：{calcCartItemsRequireDays(cart.items)} 天
              </Hint>
            </div>
            <div className="row">
              <h4>
                {' '}
                指定
                {logistic.logistics_type === 'self_pick' ? '自取' : '收貨'}
                時間{' '}
              </h4>
              <DateTimePicker
                allowNull={true}
                datetime={logistic.receipt_time}
                onChange={(value) => setRecord({receipt_time: value})}
                placeholder="由後台設定客戶的指定時間"
              />
            </div>
            <Divider>寄件資訊</Divider>
            {[
              'hct',
              'mailing',
              'tcat',
              'kerry_tj',
              'maple',
              'sf',
              'payuni',
              'xdelivery',
            ].includes(logistic.logistics_type) && (
              <Ant.Row>
                {['hct', 'mailing', 'tcat', 'kerry_tj', 'maple', 'sf'].includes(
                  logistic.logistics_type,
                ) && (
                  <Ant.Col span={24}>
                    <div className="row">
                      <h4>保密代寄</h4>
                      <Ant.Radio
                        type="checkbox"
                        // ref="is_private"
                        defaultChecked={logistic.is_delivery_private}
                        checked={logistic.is_delivery_private}
                        onChange={(e) =>
                          setRecord({
                            is_delivery_private: e.target.checked,
                            sender_address: logistic.sender_address
                              ? logistic.sender_address
                              : '',
                          })
                        }>
                        保密代寄
                      </Ant.Radio>
                      <Hint type="info">
                        可自定義寄件資訊，以自訂寄件人名義將商品寄送給收件人。
                      </Hint>
                    </div>
                  </Ant.Col>
                )}
                <Ant.Col span={12}>
                  {!['payuni', 'xdelivery'].includes(
                    logistic.logistics_type,
                  ) && (
                    <div className="row">
                      <h4>收件人地址</h4>
                      <AddressField
                        zip_code={logistic.zip_code}
                        city={logistic.receiver_city}
                        district={logistic.receiver_district}
                        address={logistic.receiver_address}
                        onChange={(obj) =>
                          setRecord({
                            ...(obj.zip_code !== undefined && {
                              zip_code: obj.zip_code,
                            }),
                            ...(obj.city && {receiver_city: obj.city}),
                            ...(obj.district && {
                              receiver_district: obj.district,
                            }),
                            ...(obj.address !== undefined && {
                              receiver_address: obj.address,
                            }),
                          })
                        }
                      />
                    </div>
                  )}
                  {['payuni', 'xdelivery'].includes(logistic?.logistics_type) &&
                    !logistic?.store_id && (
                      <div className="row">
                        <Button
                          type="primary"
                          size="small"
                          color={appConfig.colors.sub}
                          onClick={handleSelectStore}>
                          選擇門市
                        </Button>
                      </div>
                    )}
                  <div className="row">
                    <h4>收件人姓名</h4>
                    <Ant.Input
                      type="text"
                      value={logistic.receiver_name}
                      onChange={(e) =>
                        setRecord({receiver_name: e.target.value})
                      }
                    />
                  </div>
                  <div className="row">
                    <h4>收件人手機</h4>
                    <Ant.Input
                      type="text"
                      value={logistic.receiver_phone}
                      onChange={(e) =>
                        setRecord({receiver_phone: e.target.value})
                      }
                    />
                  </div>
                  <div className="row">
                    <h4>收件人市話</h4>
                    <Ant.Input
                      type="text"
                      value={logistic.receiver_tel}
                      onChange={(e) =>
                        setRecord({receiver_tel: e.target.value})
                      }
                    />
                  </div>
                  {['payuni', 'xdelivery'].includes(logistic?.logistics_type) &&
                    logistic?.store_id && (
                      <>
                        <div className="row">
                          <h4>店到店單號</h4>
                          <div>{logistic?.tracking_number}</div>
                        </div>
                        <div className="row">
                          <h4>門市編號</h4>
                          <div>{logistic?.store_id}</div>
                        </div>
                        <div className="row">
                          <h4>門市名稱</h4>
                          <div>{logistic?.store_name}</div>
                        </div>
                        <div className="row">
                          <h4>門市地址</h4>
                          <div>{logistic?.store_address}</div>
                        </div>
                        <Button
                          type="primary"
                          size="small"
                          color={appConfig.colors.sub}
                          onClick={handleSelectStore}>
                          編輯門市
                        </Button>
                      </>
                    )}
                </Ant.Col>
                <Ant.Col span={12}>
                  {logistic.is_delivery_private && (
                    <>
                      <div className="row">
                        <h4>寄件人地址</h4>
                        <AddressField
                          zip_code={logistic.sender_zip}
                          city={logistic.sender_city}
                          district={logistic.sender_district}
                          address={logistic.sender_address}
                          onChange={(obj) =>
                            setRecord({
                              ...(obj.zip_code !== undefined && {
                                sender_zip: obj.zip_code,
                              }),
                              ...(obj.city && {sender_city: obj.city}),
                              ...(obj.district && {
                                sender_district: obj.district,
                              }),
                              ...(obj.address !== undefined && {
                                sender_address: obj.address,
                              }),
                            })
                          }
                        />
                      </div>
                      <div className="row">
                        <h4>寄件人姓名</h4>
                        <Ant.Input
                          type="text"
                          value={logistic.sender_name}
                          onChange={(e) =>
                            setRecord({sender_name: e.target.value})
                          }
                        />
                      </div>
                      <div className="row">
                        <h4>寄件人手機</h4>
                        <Ant.Input
                          type="text"
                          value={logistic.sender_phone}
                          onChange={(e) =>
                            setRecord({sender_phone: e.target.value})
                          }
                        />
                      </div>
                      <div className="row">
                        <h4>寄件人市話</h4>
                        <Ant.Input
                          type="text"
                          value={logistic.sender_tel}
                          onChange={(e) =>
                            setRecord({sender_tel: e.target.value})
                          }
                        />
                      </div>
                    </>
                  )}
                </Ant.Col>
              </Ant.Row>
            )}
            {logistic.logistics_type === 'ezship' && cart && (
              <Ant.Row>
                <Ant.Col span={12}>
                  <div className="row">
                    <h4>店到店單號</h4>
                    <div>{logistic.tracking_number || '尚未產生'}</div>
                  </div>
                  <div className="row">
                    <h4>台灣便利配狀態</h4>
                    <div>{tracking_data.order_status}</div>
                  </div>
                  <div className="row">
                    <h4>通路</h4>
                    <div>
                      {EZSHIP_STATE[cart.config.deliveryConfig.st_state]}
                    </div>
                  </div>
                  <div className="row">
                    <h4>門市代號</h4>
                    <div>{cart.config.deliveryConfig.st_code}</div>
                  </div>
                  <div className="row">
                    <h4>門市名稱</h4>
                    <div>{cart.config.deliveryConfig.rstore_name}</div>
                  </div>

                  <div className="row">
                    <h4>門市電話</h4>
                    <div>{cart.config.deliveryConfig.rstore_tel}</div>
                  </div>
                  <div className="row">
                    <h4>門市地址</h4>
                    <div>{cart.config.deliveryConfig.rstore_addr}</div>
                  </div>
                  <div className="row">
                    <h4>收件人姓名</h4>
                    <div>{cart.config.deliveryConfig.receiver_name}</div>
                  </div>
                  <div className="row">
                    <h4>收件人手機</h4>
                    <div>{cart.config.deliveryConfig.receiver_phone}</div>
                  </div>
                  <div className="row">
                    <h4>收件人信箱</h4>
                    <div>{cart.config.email}</div>
                  </div>
                </Ant.Col>
                <Ant.Col span={12}>
                  <div className="row">
                    <Hint type="info">
                      需前往台灣便利配後台，手動檢查並更新至此後台之物流狀態，沒有自動追蹤功能
                    </Hint>
                  </div>
                  <div className="row">
                    <Hint type="info">不可重選/編輯超商</Hint>
                  </div>
                  <div className="row">
                    <h4>查詢連結</h4>
                    <a
                      href={LOGISTIC_TRACKING_URL['ezship']}
                      target="_blank"
                      rel="noreferrer">
                      台灣便利配店到店查詢
                    </a>
                  </div>
                  <div className="row">
                    <h4>清單查詢</h4>
                    <div>
                      <a
                        href={LOGISTIC_TRACKING_URL['ezship_home']}
                        target="_blank"
                        rel="noreferrer">
                        台灣便利配首頁
                      </a>{' '}
                      {'>'} 我的便利配 {'>'} 查詢清單
                    </div>
                  </div>
                </Ant.Col>
              </Ant.Row>
            )}
          </div>
        )}
      </Block>
    </Ant.Modal>
  );
}
